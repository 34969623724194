// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // URL_SERVICE: 'http://localhost:8080/WSRestApiYamaha/APIRest/', //DESARROLLO LOCAL
  URL_SERVICE: 'https://tswlomundoyamahabe.ebsproductodigital.com/WSRestApiMundoYamaha/APIRest/', //DESARROLLO LOCAL
  //URL_SERVICE: 'http://150.136.154.156:8080/WSRestApiMundoYamaha/APIRest/'
//  URL_SERVICE: 'https://mundoyamahabe.ebscreditodigital.com/WSRestApiMundoYamaha/APIRest/'
};

// ng build --output-hashing=all --base-href /
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
