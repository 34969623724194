import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'administrador/tipoPerfil',
    pathMatch: 'full'
  },
  {
    path: 'cliente',
    loadChildren: () => import('./aplicativo/cliente/cliente.module').then(m => m.ClienteModule)
  },
  {
    path: 'administrador',
    loadChildren: () => import('./aplicativo/administrador/administrador.module').then(m => m.AdministradorModule)
  },
  {
    path: 'usuarios-recuperar-clave',
    loadChildren: () => import('./aplicativo/usuarios-recuperar-clave/clave.module').then(m => m.ClaveModule)
  },
  // {
  //   path: '**',
  //   pathMatch: 'full',
  //   redirectTo: 'administrador/tipoPerfil'
  // },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor() {

  }
}
